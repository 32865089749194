<template>
  <form
    action
    id="customer-form-date"
    data-vv-scope="date-time-form"
    @submit.prevent="validateForm('date-time-form')"
  >
    <v-row justify="center">
      <v-col cols="12" md="7" style="text-align: center">
        <h3
          style="
            text-transform: uppercase;
            font-weight: lighter;
            color: var(--v-primary-base);
          "
        >
          {{ $t("appointment.help") }}
        </h3>
      </v-col>
      <v-col
        cols="12"
        md="7"
        style="margin: auto; overflow: auto"
        v-if="!skip_validate"
      >
        <div
          style="color: var(--v-primary-base); margin-left: 20px; z-index: 4"
        >
          <v-checkbox
            v-model="selected"
            v-validate="'required|is:true'"
            true-value="true"
            data-vv-name="share"
            false-value="false"
            :label="$t('appointments.check_consent')"
            :error-messages="errors.collect('date-time-form.share')"
            on-icon="mdi-check-circle"
            off-icon="mdi-circle-outline"
          >
            <template v-slot:label>
              <div style="margin-top: 6px">
                {{ $t("check_condicions") }}
                <a
                  @click.stop
                  target="blank"
                  :href="studio.value == 'link' ? link.value : URL"
                >
                  {{ $t("check_condicions_links") }}
                </a>
              </div>
            </template></v-checkbox
          >
        </div>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        @click="$emit('back')"
        style="height: 30px; width: 100px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px; width: 100px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as moment from "moment";
export default {
  name: "DateTimeSelect",
  props: ["select", "skip_validate"],
  data() {
    return {
      selected: false,
    };
  },
  mounted() {},
  methods: {
    async validateForm(scope) {
      this.errors.clear();
      this.$validator.validateAll(scope).then((result) => {
        //console.log("validate", result, val_time);
        if (result) {
          this.$emit("validated", this.studio);
        }
      });
    },
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    studio() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(policy);

      return policy ? policy : "none";
    },
    link() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.$route.params.token}`;
    },
  },
};
</script>
 
 

<style lang="sass">
#customer-form-date
  .v-input--selection-controls__input
    margin-top: 4px !important
  label
    color: var(--v-primary-base) !important
  .v-messages
    display: none
  .selected
    color: var(--v-primary-base) !important
</style>
